import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import AppRoot from './AppRoot';
import graphQLClientFactory from './lib/GraphQLClientFactory';
import 'cross-fetch/polyfill';

let config = {};
let endpointDomain = ''; // TODO: Grab from secrets

config.graphQLEndpoint = endpointDomain + '/api/mmcom?sc_apikey=';
// TODO: Grab from secrets


/* eslint-disable no-underscore-dangle */
let renderFunction = ReactDOM.render;



/*
  SSR Data
  If we"re running in a server-side rendering scenario,
  the server will provide JSON in the #__JSS_STATE__ element
  for us to acquire the initial state to run with on the client.

  This enables us to skip a network request to load up the layout data.
  We are emitting a quiescent script with JSON so that we can take advantage
  of JSON.parse()"s speed advantage over parsing full JS, and enable
  working without needing `unsafe-inline` in Content Security Policies.

  SSR is initiated from /server/server.js.
*/
let __JSS_STATE__ = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');
if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}
if (__JSS_STATE__) {
  // when React initializes from a SSR-based initial state, you need to render with `hydrate` instead of `render`
  renderFunction = ReactDOM.hydrate;

}

/*
  GraphQL Data
  The Apollo Client needs to be initialized to make GraphQL available to the JSS app.
  Not using GraphQL? Remove this, and the ApolloContext from `AppRoot`.
*/
// Apollo supports SSR of GraphQL queries, so like JSS SSR, it has an object we can pre-hydrate the client cache from
// to avoid needing to re-run GraphQL queries after the SSR page loads
const initialGraphQLState = __JSS_STATE__ && __JSS_STATE__.APOLLO_STATE ? __JSS_STATE__.APOLLO_STATE : null;

const graphQLClient = graphQLClientFactory(config.graphQLEndpoint, false, initialGraphQLState); // TODO: ?


/*
  App Rendering
*/
let init = () => new Promise(function(resolve, reject) {
  resolve();
});

/**
 * Get Authentication Cookie value
 *
 * @returns {boolean} Authentication Cookie value
 */
function getAuthCookie() {
  const value = `; ${document.cookie}`;
  const parts = value.split('; auth0LoggedIn=');
  return parts.length === 2;
}

function getEnv() {
  return window.REACT_APP_ENV;
}

init().then(() => {
  // HTML element to place the app into
  const rootElement = document.getElementById('root');

  renderFunction(
    <AppRoot
      graphQLClient={graphQLClient}
      path={window ? window.location.pathname : '/'}
      Router={BrowserRouter}
      ssrState={__JSS_STATE__}
      authStatus={getAuthCookie()}
      appEnv={getEnv()}
    />,
    rootElement
  );
});
