import React from 'react';

/**
 * A sample component
 *
 * @param {object} props Properties used for the component
 *
 * @returns {React.ReactElement} sample
 */
function SampleComponent(props) {
  return (
    <div className="app-container">This is Sample Component..</div>
  );
}
export default SampleComponent;
